import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import ServicePage from "../../components/service";


export default function AquaticResourcePage() {
    const {service} = useStaticQuery(graphql`
        query {
          service: servicesJson(key: {eq: "aquatic-resource"}) {
            title
            title_long
            thumbnail {
              path {
                childImageSharp {
                  gatsbyImageData(width: 1024)
                }
              }
            }
            projects {
              title
              client
              location
            }
          }
        }
    `)
    return (
        <ServicePage service={service}>
            <p>
                REC ensures thorough and efficient aquatic surveys including:
                <ul className="list-disc pl-8 mt-2">
                    <li>Electrofishing for fish population inventories and salvages</li>
                    <li>Redd/spawning surveys</li>
                    <li>Detailed habitat assessments</li>
                    <li>Water quality assessments</li>
                    <li>Qualified Aquatic Environmental Specialist (QAES) surveys</li>
                    <li>Groundwater and surface water monitoring</li>
                    <li>Turbidity monitoring and analysis</li>
                    <li>Flow analysis and monitoring during water withdrawal</li>
                    <li>De-watering assessments and monitoring</li>
                </ul>
            </p>
        </ServicePage>
    )
}
